import React, { Component } from "react"
import { Row, Col } from "react-flexbox-grid"
import { navigate } from "gatsby"
import { Carousel } from "react-responsive-carousel"
import { Link } from "gatsby"
import Hero from "../../../components/Hero"
import ContentBlock from "../../../components/ContentBlock"
import HelmetWrapper from "../../../components/HelmetWrapper"
import Layout from "../../../components/Layout"
import logos from "../../../images/careers-logos.png";
import benefitsImg from "../../../images/benefits-icon.png";
import aboutUsImg from "../../../assets/images/sm_circle_AboutUs.png"
import headerHero from "../../../assets/images/header_career.png"
import Dayno from "../../../images/Dayno.png"

import styles from "react-responsive-carousel/lib/styles/carousel.min.css"
import "../../../scss/_carouseloverride.scss"
import "./index.scss"

const pageTitle = "Careers | Harmony Biosciences"
const pageDescription =
  "Explore careers at Harmony Biosciences, a biopharmaceutical company recognized for our innovation and growth. Learn about our culture and current job openings."
const pageKeywords = "harmony biosciences careers - 70"

class careers extends Component {
  constructor() {
    super()
  }

  applyBtn = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga("send", "event", "Button", "click", "Apply Online")
    }
    window.open(
      "https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=dbdf504d-e954-4c5d-9615-0f4e2fd0da14&source=CC2&lang=en_US&selectedMenuKey=CurrentOpenings",
      "_blank"
    )
  }

  salesApplyBtn = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga("send", "event", "Button", "click", "Sales Apply")
    }
    window.open("http://careers2.hiredesk.net/?comp=TSP&tp_id=28", "_blank")
  }

  ourCultureBtn = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga("send", "event", "Button", "click", "Our Culture")
    }
    navigate("/about-us/our-culture")
  }

  render() {
    return (
      <Layout jobCode="US-HAR-2300039/Oct 2023">
        <div id="careers">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
            slug={"/about-us/careers"}
          />
          <Hero bg={headerHero}>
            <h1>Careers</h1>
          </Hero>
          <ContentBlock color="orange">
            <Row>
              <Col xs={12} md={2}>
                <Row center="xs">
                  <Col>
                    <img src={aboutUsImg} className="" />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={10}>
                <h2
                  style={{
                    paddingTop: 0,
                    fontSize: "16px",
                    paddingBottom: "25px",
                  }}
                >
                  Why work at Harmony Biosciences?
                </h2>
                <p>
                  So much of what makes <strong>Harmony Biosciences</strong> a
                  special place to work is our culture. We are proud of the
                  depth of talent across our organization, and how all of us
                  from diverse professional and personal backgrounds come
                  together, united, with the purpose of bringing hope to people
                  living with rare neurological diseases. Critical to our
                  success is how we do this – by holding true to our core
                  values. Our success as an organization depends on each and
                  every team member. It is measured by how we develop and
                  commercialize innovative therapies for people living with rare
                  neurological diseases, and ultimately touch the lives of the
                  patients and people we serve.
                </p>
              </Col>
            </Row>
          </ContentBlock>

          <ContentBlock color="blue">
            <Row>
              <Col xs={12} md={12}>
                <h2
                  style={{
                    paddingTop: 0,
                    paddingLeft: "0px",
                    fontSize: "16px",
                    paddingBottom: "25px",
                  }}
                >
                  Careers at Harmony
                </h2>

                <p className="">
                  Interested in joining the Harmony Team? Each employee at
                  Harmony contributes to our mission. Explore our current
                  openings to discover where you can make an impact on our team.
                </p>

                <p className="">
                  <a
                    href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=dbdf504d-e954-4c5d-9615-0f4e2fd0da14&source=CC2&lang=en_US&selectedMenuKey=CurrentOpenings"
                    target="_blank"
                  >
                    <button aria-label="Go to open jobs">
                      SEE CURRENT OPENINGS AND APPLY
                    </button>
                  </a>
                </p>
              </Col>
            </Row>
          </ContentBlock>

          <ContentBlock id="daynoBg" background="dayno">
            <Row>
              <Col xs={12} md={5} sm={12} lg={4}><div className="mobile-hide daynoImage"><img src={Dayno} /></div></Col>
              <Col xs={12} md={7} sm={12} lg={8}>
                <div className="daynoText">
                  <p>
                    “Being recognized for our culture and our company’s innovation
                    and growth underscores our ongoing commitment to fostering a
                    thriving workplace culture that is empathetic, inclusive, and
                    collaborative. Every day, I see our team of experts from a
                    wide variety of backgrounds and disciplines demonstrate their
                    shared conviction and teamwork in bringing hope to the people
                    and communities we serve. This recognition is a testament to
                    the amazing work and dedication of our team members across our
                    organization helping to fuel Harmony’s growth.”
                  </p>
                  <p>
                    <strong>
                      Jeffrey M. Dayno, MD, President and Chief Executive Officer
                    </strong>
                  </p>
                  <p>
                    <img className="responsive" src={logos} />
                  </p>
                </div>
              </Col>
            </Row>
          </ContentBlock>

          <ContentBlock color="rose">
            <Row>
              <Col xs={12} md={12}>
                <h2
                  style={{
                    paddingTop: 0,
                    fontSize: "16px",
                    paddingBottom: "25px",
                  }}
                >
                  Our Culture
                </h2>
                <p>
                  Our culture is innovative, empathetic, inclusive and
                  collaborative, and defined by our core values – keeping
                  patients at the heart of all we do, acting with integrity and
                  ethics, working with a one-team attitude, leading with the
                  science as we identify clinical possibilities, and delivering
                  excellence.
                </p>

                <Link to="/about-us/our-culture">
                  <button aria-label="Go to Homepage">
                    LEARN MORE ABOUT OUR CULTURE
                  </button>
                </Link>
              </Col>
            </Row>
          </ContentBlock>
          <ContentBlock id="careerBenefits">
            <div className="white-block">
              <Row>
                <Col xs={12} md={2}>
                  <Row center="xs">
                    <Col>
                      <img src={benefitsImg} className="" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={10}>
                  <h2
                    style={{
                      paddingTop: 0,
                      fontSize: "16px",
                      paddingBottom: "10px",
                    }}
                  >
                    Our Benefits
                  </h2>
                  <p>
                    <strong>Harmony Biosciences</strong> is committed to
                    providing our team members with a comprehensive benefits
                    package to cover all aspects of your professional and
                    personal well-being. In addition to the benefits highlighted
                    below, we offer programs such as intra-department
                    internships, shadowing opportunities, mentorships, and a
                    professional development stipend to support our employees’
                    development and career progression.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="green-box">
                    <p>
                      <strong>Health and Wellness:</strong>
                    </p>
                    <p>
                      <ul>
                        <li>
                          <span>
                            Medical, vision, dental, and prescription coverage
                          </span>
                        </li>
                        <li>
                          <span>Numerous voluntary benefits</span>
                        </li>
                        <li>
                          <span>Company-sponsored wellness programs</span>
                        </li>
                        <li>
                          <span>Employee Assistance Program(s)</span>
                        </li>
                      </ul>
                    </p>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="green-box">
                    <p>
                      <strong>Financial Well-Being:</strong>
                    </p>
                    <p>
                      <ul>
                        <li>
                          <span>401(k) retirement savings plan</span>
                        </li>
                        <li>
                          <span>
                            Company-paid short- and long-term disability, basic
                            life insurance, and accidental death and
                            dismemberment coverage
                          </span>
                        </li>
                        <li>
                          <span>Employee Stock Purchase Plan (ESPP)</span>
                        </li>
                      </ul>
                    </p>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="green-box">
                    <p>
                      <strong>Work/Life Balance:</strong>
                    </p>
                    <p>
                      <ul>
                        <li>
                          <span>
                            Generous paid time off and company-designated
                            holidays
                          </span>
                        </li>
                        <li>
                          <span>Paid parental leave</span>
                        </li>
                        <li>
                          <span>Flexible working hours</span>
                        </li>
                      </ul>
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </ContentBlock>
          <ContentBlock id="footnotes" color="white">
            <Row>
              <Col xs={12} md={12}>
                <p class="footnote-small">
                  Harmony Biosciences is an Equal Opportunity, e-Verify
                  Employer. All qualified applicants will receive equal
                  consideration for employment without regard to race, color,
                  national origin, religion, sex, pregnancy, marital status,
                  sexual orientation, gender identity/expression, age,
                  disability, genetic information, military service,
                  covered/protected veteran status, or any other federal, state
                  or local protected class.
                  <br />
                  <br />
                  Recruitment agencies please note: Harmony Biosciences will
                  only accept applications from agencies/business partners that
                  have been invited to work on a specific role. Candidate
                  resumes/CVs submitted without permission or directly to Hiring
                  Managers will be considered unsolicited and no fee will be
                  payable. Thank you for your cooperation.
                  <br />
                  <br />
                  <br />
                </p>
              </Col>
            </Row>
          </ContentBlock>
        </div>
      </Layout>
    )
  }
}

export default careers
